export default function paramsCopier(params = []) {
  params.forEach(function (param) {
    document.getElementById(param).value = unescape(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            escape(param).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  });
}
