import "intl-tel-input/build/css/intlTelInput.css";
import * as utilsScript from "intl-tel-input/build/js/utils.js";
import intlTelInput from "intl-tel-input";

export default function phoneChecker(
  phoneInputId = "phone",
  hiddenInputId = "phone-international"
) {
  var phoneInput = document.querySelector(`#${phoneInputId}`);
  var iti = intlTelInput(phoneInput, {
    utilsScript: utilsScript,
    initialCountry: "auto",
    geoIpLookup: function (success, failure) {
      $.get("https://ipinfo.io?token=9f8ce4f77cc869", function () {}).always(
        function (resp) {
          var countryCode = resp && resp.country ? resp.country : "fr";
          success(countryCode);
        }
      );
    },
    preferredCountries: ["fr", "be", "de", "es", "it", "pt", "sk"],
    hiddenInput: hiddenInputId,
  });

  phoneInput.addEventListener("focusout", function () {
    if (iti.isValidNumber()) {
      phoneInput.setCustomValidity("");
    } else {
      var countryCode = iti.getSelectedCountryData()["iso2"];
      var national = true;
      var numberType = intlTelInputUtils.numberType.MOBILE;

      var example = intlTelInputUtils.getExampleNumber(
        countryCode,
        national,
        numberType
      );
      phoneInput.setCustomValidity(`Ex. ${example}`);
    }
  });
}
