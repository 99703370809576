import "jquery-mask-plugin";
import "jquery-ui/ui/widgets/datepicker";

import "jquery-ui/themes/base/base.css";
import "jquery-ui/themes/base/theme.css";

export default function datePicker(inputId) {
  $(document).ready(function () {
    $(`#${inputId}`).datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: "dd/mm/yy",
      yearRange: "-100:+0",
    });
    $(`#${inputId}`).mask("99/99/9999");
  });
}
