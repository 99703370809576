import algoliaSearcher from "./algolia-searcher";
import datePicker from "./date-picker";
import phoneChecker from "./phone-checker";
import paramsCopier from "./params-copier";
import paramsLinker from "./params-linker";

class FormHelper {
  constructor() {}
}

FormHelper.prototype.algoliaSearcher = algoliaSearcher;
FormHelper.prototype.datePicker = datePicker;
FormHelper.prototype.phoneChecker = phoneChecker;
FormHelper.prototype.paramsCopier = paramsCopier;
FormHelper.prototype.paramsLinker = paramsLinker;

export default FormHelper;
